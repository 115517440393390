@import '../styles/variables';

.header {
  text-align: center;
  max-width: 47rem;
  margin: 0 auto $size-9 auto;
  padding: 0 $size-5;

  @media screen and (min-width: $breakpoint-md) {
    margin: 0 auto $size-10 auto;
    padding: 0 $size-10;
  }

  h1 {
    margin-bottom: $size-7;
    animation: $animation-title;
  }

  .linkIcons {
    margin-top: $size-8;

    a {
      display: inline-block;
      padding: 0 $size-4;
      height: fit-content;
      transform: translateY(0);
      transition: $transition-hover-up, color $transition-color;
      color: var(--color-icon);

      &:hover {
        transform: translateY(-$size-1);
        transition: $transition-hover-down, color $transition-color;
        color: var(--color-accent);
      }

      svg {
        width: auto;
        height: $size-8;
      }
    }
  }
}
