@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@500;600;700&family=Merriweather:ital,wght@0,300;0,400;0,700;1,300;1,400&display=swap");

$font-title: "Josefin Sans", sans-serif;
$font-text: "Merriweather", serif;

// Headings
@mixin heading {
  font-family: $font-title;
  font-weight: var(--font-weight-heading);
}

@mixin heading-1 {
  @include heading;
  font-size: 4rem;
  text-transform: uppercase;
  letter-spacing: 3px;

  @media screen and (max-width: $breakpoint-sm) {
    font-size: 3rem;
  }
}

@mixin heading-2 {
  @include heading;
  font-size: 1.75rem;
  font-weight: var(--font-weight-heading-small);
}

@mixin heading-3 {
  @include heading;
  font-weight: 500;
  font-size: 1.5rem;
}

@mixin heading-4 {
  font-family: $font-text;
  font-weight: 700;
  font-size: 1.25rem;
}

// Button
@mixin button-text {
  @include heading;
  font-size: 0.875rem;
  text-transform: uppercase;
}

// Body
@mixin body {
  font-family: $font-text;
  font-weight: var(--font-weight-body);
}

@mixin body-1 {
  @include body;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

@mixin body-2 {
  @include body;
  font-size: 1rem;
}

@mixin body-3 {
  @include body;
  font-size: 0.75rem;
}
