@import "../styles/variables";

.modalContainer {
  width: 100%;
  height: 100%;
  overflow: auto;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  padding: $size-10 $size-6;

  &.enter {
    transform: translate(0, 50vh);
    opacity: 0;
  }
  &.enter-active {
    transform: none;
    transition: $transition-modal-in;
    opacity: 1;
  }
  &.exit {
    transform: none;
    opacity: 1;
  }
  &.exit-active {
    transform: translate(0, 50vh);
    transition: $transition-modal-out;
    opacity: 0;
  }

  .projectContent {
    max-width: 60rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .closeButton {
      @include icon-button-style;
    }

    .projectHeader {
      text-align: center;
      margin-bottom: $size-9;

      h1 {
        animation: $animation-title;
      }
      h3 {
        animation: $animation-subTitle;
      }
    }

    .sectionTitle {
      margin-bottom: $size-5;
      text-transform: uppercase;
      color: var(--color-accent);
    }
    .sectionTitle,
    h4 {
      align-self: flex-start;
    }

    .overview {
      width: 100%;
      p {
        margin-bottom: $size-5;
        b {
          text-transform: capitalize;
        }
      }
    }

    .contentDivider {
      margin: $size-8 0;
      width: 100%;
      border-color: var(--color-text);
    }

    p {
      width: 100%;
    }

    img,
    video {
      width: 100%;
      border-radius: $border-radius-large;
      margin: $size-2 0 $size-9 0;
      @include tile-box-shadow;

      &:has(+ .caption) {
        margin-bottom: $size-4;
      }
    }

    .twoImg {
      display: flex;
      justify-content: space-evenly;
      width: 100%;

      img {
        width: fit-content;
        max-width: 100%;
        max-height: 540px;
      }

      @media screen and (max-width: $breakpoint-md) {
        flex-direction: column;
        align-items: center;
      }
    }

    video {
      outline: none;
      opacity: 0;
      transition: $transition-video-load;
    }

    .caption {
      @include body-2;
      margin-bottom: $size-9;
      font-style: italic;
      text-align: center;
    }
  }
}
