@import "../styles/variables";

.projectTile {
  height: 20rem;
  max-width: 40rem;
  margin: auto;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border-radius: $border-radius-default;
  overflow: hidden;
  transform: translateY(0);
  transition: $transition-hover-up, box-shadow $animation-duration-1;
  @include tile-box-shadow;

  &:hover {
    transform: translateY(-$size-2);
    transition: $transition-hover-down, box-shadow $animation-duration-1;
  }

  .projectImage {
    width: 100%;
    height: 100%;
    background-size: cover;
  }

  .projectLabel {
    padding: $size-4;
    background-color: var(--color-background-light);
  }
}
