@import "../../styles/variables";

.passwordContainer {
  max-width: 32rem;
  margin: $size-10 auto 0 auto;
  text-align: center;
  border-radius: $border-radius-large;
  line-height: normal;
  @include tile-box-shadow;
  border: 1px solid var(--color-icon);
  background-color: var(--color-background-light);
  padding: $size-8;
  @media screen and (min-width: $breakpoint-sm) {
    padding: $size-8 $size-9;
  }

  .passwordIcon {
    width: auto;
    height: $size-7;
    margin-bottom: $size-5;
    color: var(--color-icon);
  }

  .email {
    text-decoration: underline;
  }

  .formContainer {
    width: fit-content;
    margin: $size-8 auto 0 auto;
  }

  .passwordForm {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: $size-3;

    .passwordInput {
      @include body-2;
      width: 12rem;
      padding: $size-1;
      border-top: none;
      border-right: none;
      border-left: none;
      outline: none;
      margin-right: $size-3;
      transition: border-color $transition-color;
      color: var(--color-text);
      border-bottom: 2px solid var(--color-button);
      background-color: var(--color-background-light);

      &:focus {
        border-color: var(--color-button-hover);
      }
    }

    .passwordButton {
      @include button-style;
    }
  }

  .passwordError {
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: italic;

    .passwordWarning {
      height: $size-4;
      width: auto;
      margin-right: $size-2;
    }
  }
}
