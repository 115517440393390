$size-1: 0.25rem;
$size-2: 0.5rem;
$size-3: 0.75rem;
$size-4: 1rem;
$size-5: 1.25rem;
$size-6: 1.5rem;
$size-7: 1.75rem;
$size-8: 2.5rem;
$size-9: 3.75rem;
$size-10: 6.25rem;
