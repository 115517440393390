@import "sizes";

$animation-duration-1: 0.2s;
$animation-duration-2: 0.4s;
$animation-duration-3: 0.6s;

$animation-title: $animation-duration-3 titleEntrance ease-in;
$animation-subTitle: $animation-duration-3 subtitleEntrance ease-in;

$transition-hover-up: transform $animation-duration-1 ease-in;
$transition-hover-down: transform $animation-duration-1 ease-out;
$transition-modal-in: $animation-duration-2 ease-in;
$transition-modal-out: $animation-duration-2 ease-out;
$transition-color: $animation-duration-1 ease;
$transition-background-color: background-color $animation-duration-2 ease;
$transition-video-load: opacity $animation-duration-2 ease;

@keyframes titleEntrance {
  0% {
    transform: translate(0, $size-5);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes subtitleEntrance {
  0% {
    opacity: 0;
    transform: translate(0, $size-3);
  }
  33% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
