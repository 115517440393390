@import "animations";
@import "borderRadius";
@import "breakpoints";
@import "sizes";
@import "typography";

@mixin tile-box-shadow {
  box-shadow: 0 $size-1 $size-2 2px var(--color-tile-shadow);
}

@mixin button-style {
  @include button-text;
  padding: $size-2 $size-4;
  border-radius: $size-7;
  transition: background-color $transition-color;
  height: $size-7; // Fixes text vertical alignment issue
  border: none;
  outline: none;
  text-decoration: none;
  background-color: var(--color-button);
  color: var(--color-text-light);

  &:hover {
    background-color: var(--color-button-hover);
  }
}

@mixin icon-button-style {
  position: fixed;
  top: $size-6;
  right: $size-6;
  border: none;
  outline: none;
  padding: $size-2;
  z-index: 1;
  border-radius: 100%;
  background-color: transparent;

  &:hover svg {
    color: var(--color-icon-button-hover);
  }

  svg {
    width: auto;
    height: $size-7;
    transition: color $transition-color;
    color: var(--color-icon-button);
  }
}
