@import "./styles/theme";
@import "./styles/variables";

html {
  font-size: 12px;
  overflow: hidden;
  height: 100vh;

  @media screen and (min-width: $breakpoint-sm) {
    font-size: 14px;
  }
  @media screen and (min-width: $breakpoint-lg) {
    font-size: 16px;
  }
}

h1,
h2,
h3,
h4,
p {
  padding: 0;
  margin: 0;
}

h1 {
  @include heading-1;
  color: var(--color-accent);
  margin: 0 0 $size-2 0;
}

h2 {
  @include heading-2;
}

h3 {
  @include heading-3;
}

h4 {
  @include heading-4;
  margin: $size-2 0 $size-3 0;
}

p {
  @include body-1;
  margin-bottom: $size-5;

  &.body2 {
    @include body-2;
    margin-bottom: 0;
  }
  &.body3 {
    @include body-3;
    margin-bottom: 0;
  }
}

b {
  font-weight: 700;
}

a {
  color: var(--color-accent);
  &:hover {
    color: var(--color-accent);
  }
}

.app {
  overflow: hidden;
  height: 100vh;
}

.appContainer,
.modalContainer {
  transition: $transition-background-color, color $transition-color;
  background-color: var(--color-background);
  color: var(--color-text);

  ::selection {
    color: var(--color-text-light);
    background: var(--color-highlight);
  }
}

.appContainer {
  overflow: auto;
  height: 100%;

  .themeToggle {
    @include icon-button-style;
  }
  // Overrides for Bootstrap Container
  .projectsGrid {
    padding: $size-9 $size-8;
    min-height: calc(100vh - 4rem); // account for footer height

    @media screen and (min-width: $breakpoint-md) {
      padding: $size-10 $size-9;
    }
    max-width: 90rem;

    .projectsRow {
      justify-content: center;
    }
    .projectColumn {
      padding: $size-4;
    }
  }

  .footer {
    width: 100%;
    height: 4rem;
    text-align: center;
    padding: 0 $size-8;
    font-style: italic;
  }
}
